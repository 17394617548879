import React, { useEffect, useState } from "react";
import { get } from "lodash";
import AsyncGet from "../../../../components/Materials/AsyncGet";
import { formatNumber } from "../../../../helpers/format";
import Spinner from "../../../../components/Spinner";
import { Title } from "../../../../components/Elements";
import { getCurrentClub, getCurrentClubCurrency, getCurrentMember } from "../../../../helpers/storage";

let balanceTimeout = null;
const Balance = ({ fontSize = "f18", animationDisabled = false, loadOnMount = true, className = "", spinnerColor }) => {
    const [trigger, setTrigger] = useState("");
    useEffect(() => {
        setTrigger(Date.now());
        clearTimeout(balanceTimeout);
        balanceTimeout = setTimeout(() => {
            setTrigger(Date.now());
        }, 7500);
    }, [loadOnMount]);

    return (
        <AsyncGet
            url="/payment/get-user-balance"
            loadOnMount={trigger}
            permissionText="Payment Get User Balance"
            paramsData={{ club: getCurrentClub(), member_id: getCurrentMember() }}
            render={({ fetching, loaded, data }) => {
                return (
                    <>
                        {loaded === false && fetching === true && <Spinner color={spinnerColor} />}
                        {loaded === true && fetching === false &&
                            <Title text={`${getCurrentClubCurrency()}${formatNumber(get(data, 'amount', 0))}`} fontSize={fontSize} animationDisabled={animationDisabled} className={className} />
                        }
                    </>
                )
            }}
        />
    )
}
export default Balance;