import React from "react";
import Section from "../components/Section";
import Center from "../components/Center";
import { get } from "lodash";
import { Editor, Spacer, Text, Title } from "../components/Elements";
import Link from "../components/Link";
import style from './style.module.scss';
import { getCurrentClub } from "../helpers/storage";
import AsyncGet from "../components/Materials/AsyncGet";

const StaticFooter = () => {
    return (
        <AsyncGet
            url="/header"
            permissionText={false}
            loadOnMount={true}
            paramsData={{
                club: getCurrentClub(),
            }}
            render={({ fetching, loaded, data }) => {
                const usefulLinks = get(data, 'useful_links', []);
                const copyright = get(data, 'copyright');
                const useful_links_title = get(data, 'useful_links_title');
                return (
                    <>
                        {loaded === true && fetching === false &&
                            <>
                                <Section>
                                    <Center>
                                        <div className={style.bottomWrap}>
                                            {usefulLinks && usefulLinks.length > 0 &&
                                                <div className={style.usefulLinks}>
                                                    <Title text={useful_links_title} fontSize="f24" />
                                                    <Spacer />
                                                    {usefulLinks.map((link, i) => {
                                                        return (
                                                            <div key={`useful_links_${i}`}>
                                                                {i !== 0 && <Spacer size="s10" />}
                                                                <Link className="underlined" to={get(link, 'url')}><Text text={get(link, 'label')} /></Link>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                            {copyright && <Editor text={copyright.replaceAll("{year}", new Date().getFullYear())} className={style.copyright} />}
                                        </div>
                                    </Center>
                                </Section>
                            </>
                        }
                    </>
                )
            }}
        />

    )
}

export default StaticFooter;