import React, { useEffect, useState } from "react";
import style from './style.module.scss';
import Center from "../components/Center";
import Link from "../components/Link";
import AsyncGet from "../components/Materials/AsyncGet";
import { getCurrentClub, getCurrentMember, isFiveHS } from "../helpers/storage";
import { get } from "lodash";
import Balance from "../pages/Widgets/Shared/Balance";
import { isPA } from "../helpers/hierarchy";
import { useDispatch, useSelector } from "react-redux";
import { updateStore } from "../actions/storage";

let popupTimer = null;
const Bar = ({ fetching, data, loadBalance, setPopup, setOpenMobileMenu }) => {
    const [selectedMember, setSelectedMember] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedMember(data && data.length > 0 && data.find(d => get(d, 'id') === getCurrentMember()));
    }, [data]);

    useEffect(() => {
        dispatch(updateStore({ paMember: selectedMember }));
    }, [selectedMember])

    let spinnerColor = "var(--pink)";
    if (isFiveHS()) {
        spinnerColor = "var(--cream)";
    }

    return (
        <div className={style.memberBar}>
            <Center className={style.items}>
                <div className={style.item}>
                    <span>Member View:</span>
                    <span>{fetching ? "Loading..." : get(selectedMember, 'fullname')}</span>
                    <span><Link className={`underlined ${style.link}`} to="/my-profile/member-select"><span>(Change)</span></Link></span>
                </div>
                {selectedMember &&
                    <>
                        <div className={style.item}>
                            <div className={style.separator}></div>
                        </div>
                        <div className={style.item}>
                            <span>Balance:</span>
                            <span><Balance animationDisabled={true} loadOnMount={loadBalance} className={style.balance} spinnerColor={spinnerColor} fontSize="f16" /></span>
                            <span>
                                <Link className={`underlined ${style.link}`}
                                    onClick={() => {
                                        setOpenMobileMenu(false);
                                        clearTimeout(popupTimer);
                                        popupTimer = setTimeout(() => {
                                            setPopup('topup-balance')
                                        }, 401);
                                    }}
                                ><span>Make a Payment</span></Link>
                            </span>
                        </div>
                    </>
                }
            </Center>
        </div>
    )
}

const PAMenu = (props) => {
    const storage = useSelector((state) => get(state, 'storage', {}));
    const isPersonalAssistant = isPA(storage);
    return (
        <>
            {isPersonalAssistant &&
                <AsyncGet
                    url="/pa-members"
                    permissionText="Account Pa Members"
                    loadOnMount={true}
                    paramsData={{
                        club: getCurrentClub(),
                    }}
                    render={({ fetching, data }) => {
                        return (
                            <Bar fetching={fetching} data={data} {...props} />
                        )
                    }}
                />
            }
        </>
    )
}
export default PAMenu;