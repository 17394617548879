import React from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { get } from "lodash";

import AsyncGet from '../components/Materials/AsyncGet';
import Spinner from "../components/Spinner";
import { makeProfileData, updateStore } from "../actions/storage";
import { getCurrentClub } from "../helpers/storage";
import Header from "./Header";
import Footer from "./Footer";
import Media from "../components/Media";
import Redirect from "./Redirect";

import style from './style.module.scss';

const Layout = ({ showHeader = true }) => {
    const dispatch = useDispatch();
    return (
        <AsyncGet
            url="/profile"
            loadOnMount={true}
            onFetch={({ data }) => {
                if (get(data, 'id')) {
                    dispatch(makeProfileData(data))
                }
            }}
            permissionText={false}
            render={({ fetching, loaded }) => {
                return (
                    <>
                        {loaded === false && fetching === true &&
                            <div className={`${style.content} ${style.full}`}>
                                <Spinner size="large" className={style.loader} color='var(--spinner-color)' />
                            </div>
                        }
                        {loaded === true && fetching === false &&
                            <AsyncGet
                                url="/header"
                                permissionText={false}
                                loadOnMount={true}
                                paramsData={{
                                    club: getCurrentClub(),
                                }}
                                onFetch={({ data }) => {
                                    dispatch(updateStore({ headerFooter: data }));
                                }}
                                render={({ fetching, loaded, data }) => {
                                    return (
                                        <>
                                            {showHeader === true &&
                                                <>
                                                    <div className={style.siteBg}>
                                                        <div className={style.desktop}>
                                                            <Media src={get(data, 'site_bg_image')} width={1920} className={style.img} />
                                                        </div>
                                                        <div className={style.mobile}>
                                                            <Media src={get(data, 'site_bg_mobile_image')} width={1600} className={style.img} />
                                                        </div>
                                                    </div>
                                                    <Header fetching={fetching} loaded={loaded} data={data} />
                                                </>
                                            }
                                            <div className={style.content}>
                                                <Outlet />
                                            </div>
                                            <Redirect />
                                            {showHeader === true && <Footer data={data} />}
                                        </>
                                    )
                                }}
                            />
                        }
                    </>

                )
            }}
        />
    )
}
export default Layout;