import Cookies from 'universal-cookie';
import { fivehsLogo, maximesLogo, oswaldsLogo } from './images';
import { get } from 'lodash';

export const getClubs = () => {
    return {
        "maximes": {
            "name": "Maxime's",
            "currency": {
                "code": "USD",
                "symbol": getCurrencySymbol("USD"),
            }
        },
        "oswalds": {
            "name": "Oswald's",
            "currency": {
                "code": "GBP",
                "symbol": getCurrencySymbol("GBP"),
            }
        },
        "fivehs": {
            "name": "5 Hertford Street",
            "currency": {
                "code": "GBP",
                "symbol": getCurrencySymbol("GBP"),
            }
        },
    }
}

export const getClubImage = (club) => {
    let clubs = {
        "Maxime's": maximesLogo,
        "Oswald's": oswaldsLogo,
        "5 Hertford Street": fivehsLogo,
    }
    return get(clubs, club);
}

export const getCurrentClub = () => {
    return getCookie("club") || process.env.REACT_APP_SITE;
}

export const getCurrentClubName = () => {
    return get(getClubs(), `${getCurrentClub()}.name`);
}

export const getCurrentClubCurrency = (type = "symbol") => {
    return get(getClubs(), `${getCurrentClub()}.currency.${type}`);
}

export const getCurrencySymbol = (currency = "GBP") => {
    const currencies = {
        "GBP": "£",
        "USD": "$",
    }
    return get(currencies, currency);
}

export const getPaymentMethodType = (type = "") => {
    if (["directdebit_gb", "direct debit", "ach"].includes(type.toLowerCase())) {
        return "directdebit";
    }
    return "card";
}

export const getUserClubStatus = (userData) => {
    return get(userData, `Club_Membership_Relation__c.${getCurrentClubName()}.Status__c`);
}

export const getUserClubAttr = (userData, attribute) => {
    return get(userData, `Club_Membership_Relation__c.${getCurrentClubName()}.${attribute}`);
}

export const getCurrentMember = () => {
    return getCookie("current_member");
}

export const getCookie = (cookiename = "token", parseJson = false) => {
    // Get name followed by anything except a semicolon
    var cookiestring = RegExp(cookiename + "=[^;]+").exec(document.cookie);
    // Return everything after the equal sign, or an empty string if the cookie name not found
    cookiestring = decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "");
    if (parseJson && cookiestring) {
        cookiestring = JSON.parse(cookiestring);
    }
    return cookiestring || "";
}

export const setCookie = (value, cookiename = "token") => {
    const cookies = new Cookies();
    cookies.set(cookiename, value, {
        path: "/",
        secure: true,
        expires: 0,
        sameSite: 'Strict'
    });
}

export const deleteCookie = (cookiename = "token") => {
    const cookies = new Cookies();
    cookies.remove(cookiename, {
        path: "/",
        secure: true,
        sameSite: 'Strict'
    });
}

export const getPaymentMethodOptions = () => {
    if (getCurrentClub() === "maximes") {
        return [
            {
                "id": "ACH",
                "text": "ACH (US Bank Accounts Only)",
            },
            {
                "id": "Credit Card",
                "text": "Credit Card",
            }
        ]
    }
    return [
        {
            "id": "Direct Debit",
            "text": "Direct Debit (UK Bank Accounts Only)",
        },
        {
            "id": "Credit Card",
            "text": "Credit Card",
        }
    ];
}

export const isFiveHS = () => {
    return getCurrentClub() === "fivehs";
}

export const isOswalds = () => {
    return getCurrentClub() === "oswalds";
}

export const getAchDBlabel = () => {
    if (isFiveHS() || isOswalds()) {
        return {
            "account-label": "Direct Debit GB & Card Management",
            "button-label": "Add/Update Direct Debit",
            "method-label": "Direct Debit GB",
            "detailed-label": "Direct Debit (UK Bank Accounts only)",
            "label": "Direct Debit",
        }
    }
    return {
        "account-label": "ACH & Card Management",
        "button-label": "Add/Update ACH",
        "method-label": "ACH",
        "label": "ACH",
        "detailed-label": "ACH (US Bank Accounts only)",
    };
}