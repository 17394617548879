import React, { useEffect, useRef, useState } from "react";
import style from './style.module.scss';
import AsyncForm from "../../../components/Materials/AsyncForm";
import { Button } from "../../../components/Field";
import { get } from "lodash";
import { adyenConfig } from '../../../helpers/adyen';
import AdyenCheckout from "@adyen/adyen-web";
import '@adyen/adyen-web/dist/adyen.css';
import { showToast } from "../../../helpers/notify";
import AsyncGet from "../../../components/Materials/AsyncGet";
import { getCurrentClub, getCurrentMember } from "../../../helpers/storage";

const PaymentCardForm = ({ paymentMethod, setLoad = () => { }, formVal, onDialogClose = () => { }, content, topupUrl = "/payment/top-up-balance", topupSubmitUrl = "/payment/topup-submit-detail", formData = {}, usedFor = "topup", onClose, toastMsg = "Balance Payment successfull" }) => {
    const adyenContainerRef = useRef(null);
    const [state, setState] = useState(null);
    const [dropin, setDropin] = useState(null);
    const [addData, setAddData] = useState(false);
    const [actionData, setActionData] = useState(false);
    const submitRef = useRef(null);

    useEffect(() => {
        const initializeAdyen = async () => {
            const configuration = {
                clientKey: adyenConfig.clientKey,
                environment: adyenConfig.environment,
                paymentMethodsConfiguration: {
                    card: {
                        hasHolderName: true,
                        holderNameRequired: true,
                        enableStoreDetails: true,
                    },
                    threeDS2: {
                        challengeWindowSize: '05',
                    }
                },
                paymentMethodsResponse: paymentMethod,
                onChange: (state, dropin) => {
                    setState(state);
                    setDropin(dropin);
                },
                showPayButton: false,
                onAdditionalDetails: (state, dropin) => {
                    setDropin(dropin);
                    setAddData(state);
                },
            };
            const checkout = await AdyenCheckout(configuration);
            checkout.create('dropin').mount(adyenContainerRef.current);
            setLoad("paymentLoaded");
        };

        initializeAdyen();
    }, []);

    return (
        <AsyncGet
            url={topupSubmitUrl}
            method="post"
            loadOnMount={get(addData, 'data.details.threeDSResult')}
            permissionText="Payment Topup Submit Detail"
            paramsData={{ details: get(addData, 'data.details', {}), actionData, club: getCurrentClub(), member_id: getCurrentMember(), ...formData }}
            onFetch={({ data }) => {
                if (get(data, 'id')) {
                    if (usedFor === "topup") {
                        if (get(data, 'amount') && get(data, 'psp_reference')) {
                            showToast(toastMsg);
                            onDialogClose()
                        }
                    } else {
                        onClose(data);
                    }
                }
            }}
            onError={() => {
                onDialogClose();
            }}
            render={({ fetching: submitting }) => {
                return (
                    <AsyncForm
                        url={topupUrl}
                        label="Balance payment"
                        id="addCard"
                        type="create"
                        title={false}
                        permissionText="Payment Top Up Balance"
                        fixedSaveButton={false}
                        showToastOnSuccess={false}
                        submitButton={false}
                        onSave={({ data }) => {
                            if (get(data, 'action')) {
                                dropin.handleAction(get(data, 'action'));
                                setActionData(data);
                                setLoad("3ds");
                            } else if (get(data, 'id')) {
                                if (usedFor === "topup") {
                                    if (get(data, 'amount') && get(data, 'psp_reference')) {
                                        showToast(toastMsg);
                                        onDialogClose()
                                    }
                                } else {
                                    onClose(data);
                                }
                            }
                        }}
                        paramsData={{
                            club: getCurrentClub(),
                            member_id: getCurrentMember(),
                            ...formData,
                        }}
                        render={({ change, fetching }) => {
                            return (
                                <>
                                    <div className={`${style.wrap} ${style.additionalWrab}`}>
                                        {content && <div className={style.content}>{content()}</div>}
                                        <div ref={adyenContainerRef} id="card-container" className={style.paymentMake} />
                                    </div>
                                    <div className={style.float}>
                                        <div className={style.action}>
                                            <div></div>
                                            <Button
                                                label="Make a payment"
                                                onClick={() => {
                                                    if (state && state.isValid) {
                                                        change("payment_details", get(state, 'data'));
                                                        change("amount", get(formVal, 'amount'));
                                                        setTimeout(() => {
                                                            submitRef.current.click();
                                                        }, 200);
                                                    }
                                                }}
                                                disabled={!get(state, 'isValid', false) || actionData !== false}
                                                fetching={fetching || submitting}
                                            />
                                        </div>
                                        <button type="submit" ref={submitRef} style={{ display: "none" }} />
                                    </div>
                                </>
                            )
                        }}
                    />
                )
            }}
        />
    );
}
export default PaymentCardForm;